/*  ==========================================================================
    COOKIEBANNER
    ========================================================================== */

@mixin cookiebanner($bg-banner,$bg-border,$bg-button,$clr-button,$clr-link,$clr-text) {
  align-items: center;
  background-color: $bg-banner;
  border-top: 1px solid $bg-border;
  bottom: 0;
  display: flex;
  min-height: 50px;
  padding: 10px 40px;
  position: fixed;
  top: auto;
  .cb-infotext {
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 220px 0 0;
    position: relative;
    .cb-text {
      color: $clr-text;
      font-size: 12px;
      width: 100%;
      a {
        color: $clr-link;
        text-decoration: underline;
        &:visited {
          color: $clr-link;
        }
        &:hover {
          color: $clr-link;
        }
      }
    }
    .cb-button {
      background-color: $bg-button;
      color: $clr-button;
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      margin-top: -15px;
      padding: 0;
      position: absolute;
      right: 0;
      top: 50%;
      width: 200px;
      z-index: 5;
      &:visited {
        color: $clr-button;
      }
      &:hover {
        color: $clr-button;
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // mobile
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    .cb-infotext {
      padding-right: 0;
      .cb-text {
        margin-bottom: 10px;
      }
      .cb-button {
        display: block;
        margin-top: 0;
        position: static;
        right: auto;
        top: auto;
        width: 100%;
      }
    }
  }
}