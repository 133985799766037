/*  ==========================================================================
    MISC
    Styling for various unclassifiable things which are mostly at the bottom
    of the page, after the footer.
    ========================================================================== */

// scroll up
.bn-scroll-up {
  background-color: $clr__ci--secondary;
  border: 1px solid $clr__white;
  border-radius: 100%;
  color: $clr__typo--secondary;
  cursor: pointer;
  display: none;
  font-size: 2.5em;
  height: 50px;
  line-height: 48px;
  position: fixed;
  right: -100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  visibility: hidden;
  width: 50px;
  z-index: $unit__zi--scroll-up;
  &:hover {
    background-color: $clr__ci--primary;
  }
  &.bn-scroll-up--activated {
    display: block;
    &.bn-scroll-up--visible {
      right: 40px;
      visibility: visible;
    }
  }
}
// quick request
.bn-quick-request {
  visibility: visible !important;
  &.bn-quick-request--v1 {
    @include quick-request--v1();
  }
  &.bn-quick-request--v2 {
    @include quick-request--v2();
  }
}
body {
  &.header-v4 {
    .bn-quick-request {
      &.bn-quick-request--v1 {
        @include quick-request--v1();
      }
    }
  }
}
// ehotelier pop up
.bn-ehotelier-pop-up {
  bottom: 0;
  height: 100%;
  left: 0;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  transition: max-height 0.5s;
  transition-delay: 0.5s;
  width: 100%;
  z-index: $unit__zi--pop-up;
  > .bn-grid {
    padding: 40px 40px 0 40px;
  }
  .bn-ehotelier-pop-up__overlay {
    background-color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.5s;
    transition-delay: 0s;
    width: 100%;
    z-index: 5;
  }
  .bn-ehotelier-pop-up__paper {
    background-color: $clr__white;
    box-shadow: 0 0 2px 0 rgba($clr__black,0.4);
    height: calc(100vh - 40px);
    position: relative;
    z-index: 10;
    > div {
      background-color: rgba($clr__ci--primary,0.2);
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 40px 40px 0 40px;
    }
    .bn-ehotelier-pop-up__head {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      margin-bottom: 40px;
      white-space: nowrap;
      .bn-ehotelier-pop-up__close {
        color: $clr__ci--primary;
        cursor: pointer;
        display: inline-block;
        font-size: 3.5em;
        width: 50px;
        &:hover {
          color: $clr__ci--secondary;
        }
      }
      .bn-ehotelier-pop-up__controls {
        @include clearfix();
        width: calc(100% - 50px);
        .bn-ehotelier-pop-up__arrows {
          @include slider--arrows($clr__ci--primary);
          display: inline-block;
          float: right;
          .bn-arrows__arrow {
            font-size: 3.5em;
            .bn-icon {
              font-size: 1.0em;
            }
            &.bn-arrows__arrow-next {
              margin-left: 10px;
            }
          }
        }
        .bn-ehotelier-pop-up__control-info {
          color: $clr__ci--primary;
          display: inline-block;
          float: right;
          font-size: 1.6em;
          margin-right: 10px;
        }
      }
    }
    .bn-ehotelier-pop-up__holder {
      @include offers--detail($clr__ci--primary,$clr__ci--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--secondary);
      @include rooms--detail($clr__ci--primary,$clr__ci--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--primary,$clr__typo--secondary,$clr__ci--secondary,$clr__typo--secondary);
      @include scrollbar($clr__scrollbar,5px);
      max-height: 100%;
      overflow-y: auto;
      .bn-offer-box,
      .bn-room-box {
        padding-bottom: 40px;
      }
    }
  }
  &.bn-ehotelier-pop-up--open {
    max-height: 100%;
    transition-delay: 0s;
    .bn-ehotelier-pop-up__overlay {
      background-color: rgba($clr__black,0.8);
      transition-delay: 0.5s;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  // scroll up
  .bn-scroll-up {
    bottom: 40px;
    font-size: 2.0em;
    left: -100%;
    right: auto;
    top: auto;
    transform: none;
    &.bn-scroll-up--activated {
      &.bn-scroll-up--visible {
        left: 40px;
        right: auto;
      }
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    .bn-ehotelier-pop-up__paper {
      > div {
        padding-top: 20px;
      }
      .bn-ehotelier-pop-up__head {
        margin-bottom: 20px;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  // scroll up
  .bn-scroll-up {
    bottom: 20px;
    &.bn-scroll-up--activated {
      &.bn-scroll-up--visible {
        left: 20px;
      }
    }
  }
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    .bn-ehotelier-pop-up__paper {
      > div {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  // ehotelier pop up
  .bn-ehotelier-pop-up {
    > .bn-grid {
      padding: 20px 20px 0 20px;
    }
    .bn-ehotelier-pop-up__paper {
      .bn-ehotelier-pop-up__head {
        .bn-ehotelier-pop-up__controls {
          .bn-ehotelier-pop-up__control-info {
            display: none;
          }
        }
      }
    }
  }
}