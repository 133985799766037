/*  ==========================================================================
    IMPRESSIONS V2
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions-v2--arrows: $clr__ci--primary;
$module-clr__impressions-v2--arrows-hover: $clr__ci--secondary;

/*
 * MODULE
 */

.bn-impressions-v2 {
  .bn-impressions-v2__arrows {
    @include slider--arrows-v2($module-clr__impressions-v2--arrows,$module-clr__impressions-v2--arrows-hover);
  }
  .bn-impressions-v2__slider {
    @include clearfix();
    overflow: hidden;
    .bn-impressions-v2__image {
      display: block;
      float: left;
      padding: 0 10px;
      width: 50%;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-impressions-v2 {
    .bn-impressions-v2__slider {
      .bn-impressions-v2__image {
        padding: 0 5px;
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-impressions-v2 {
    .bn-impressions-v2__slider {
      .bn-impressions-v2__image {
        float: none;
        padding: 0;
        width: 100%;
      }
    }
  }
}